#tableExortationNotes {
   width: 100%;
    border-collapse: collapse;
    border: 2px solid #263796;
    color: #263796;
    padding: 5px; /* Add spacing */
    min-width: 700px;
        /* Set minimal width */
        overflow-x: auto;
        /* Allow horizontal scroll */
}
#tableExortationNotes td:nth-child(1),
#tableExortationNotes td:nth-child(3) {
    width: 400px;
    /* Set the maximum width for columns 1 and 3 */
}
#tableExortationNotes td:nth-child(2){
    width: 300px;
    /* Set the maximum width for columns 2 */

}
#tableExortationNotes input[type="checkbox"] {
    /* Remove checkbox styles */
    /* appearance: none; */
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    outline: none;
    border: none; 
    background-color: transparent;
    margin-right: 5px; /* Add spacing between checkbox and label */
}
#tableExortationNotes input[type="checkbox"]:disabled {
    opacity: 0.9;
    /* Adjust opacity as needed */
    position: relative;
}
#tableExortationNotes input[type="checkbox"]:disabled:checked::before {
    content: '\2713';
    /* Unicode checkmark character */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    /* Adjust size as needed */
    color: black;
    /* Adjust color as needed */
}
#tableExortationNotes td, #tableExortationNotes th {
    border: none;
    padding: 5px; /* Add spacing */
}

#tableExortationNotes tr:first-child th {
    border-top: 1px solid #263796;
}

#tableExortationNotes tr:last-child td {
    border-bottom: 1px solid #263796;
}

#tableExortationNotes tr td:first-child {
    border-left: 1px solid #263796;
}

#tableExortationNotes tr td:last-child {
    border-right: 1px solid #263796;
}
.show-border {
    border: 2px solid #263796;
}

.show-border-top {
    border-top: 2px solid #263796;
}


ul.ul-none {
    list-style: none;
    padding: 0;
}

.tdTop {
    vertical-align: top;
}
.bold-text {
    font-weight: bold;
}
.bold-text-black {
    font-weight: bold;
    color: black;
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
    #tableExortationNotes {
     overflow-x: auto;
    /* Allow horizontal scroll on mobile */
        font-size: 10px; /* Adjust font size for mobile devices */
     
      
    }
    #tableExortationNotes td, #tableExortationNotes th {
        padding: 3px; /* Adjust spacing for mobile devices */
    }
    .show-border {
        border: 1px solid #263796; /* Adjust border size for mobile devices */
    }
    .show-border-top {
        border-top: 1px solid #263796; /* Adjust border size for mobile devices */
    }
}

@media (max-width: 992px) {
    /* Styles for screens narrower than 992px */

    #tableExortationNotes {
        overflow-x: auto;
        /* Allow horizontal scroll on mobile */
        font-size: 10px;
        /* Adjust font size for mobile devices */
     
    }
}